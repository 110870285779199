/* SCSS HEX */
$black: #000000ff;
$battleship-gray: #8C8C8Dff;
$dim-gray: #6D6E75ff;
$apple-green: #9EBB35ff;
$apple-green-hover: #b9d260;
$light-gray: #F8F8F8;
$size-header: 120px;
$padding-header: 100px;

html, body {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
  font-family: Arial;
  letter-spacing: .075rem;
  color: $dim-gray;
}

a {
  text-decoration: none;
}

.app-header {
  width: 100%;
  max-width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  margin: 0 auto;
  height: $size-header;
  background-color: #FFFFFF;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2500;

  & .mobile-menu {
    display: none;
  }

  & .app-logo {
    position: absolute;
    left: 10%;

    & img {
      width: 120px;
    }
  }

  & .app-navigation {
    width: 100%;

    & .close-mobile-menu {
      display: none;
    }

    & ul {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      gap: 2.5rem;
      justify-content: center;
      align-items: center;

      & li {
        list-style: none;

        & a {
          text-decoration: none;
          color: black;

          &.active {
            color: $apple-green;
          }
        }
      }
    }
  }
}

.app-container {
  margin-top: $size-header;
}

.app-banner {
  width: 100%;
  height: 80vh;
  display: flex;
  position: relative;
  background-image: url(./images/family_small.jpg);
  background-size: cover;
  background-position: top;

  & .opacity {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  & .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin: 75px auto 0 auto;
    padding: 0;
    z-index: 1000;

    & a {
      color: #FFFFFF;
      padding: 18px 16px;
      background-color: $apple-green;
      border-radius: 3px;
      transition: all ease .3s;
      &:hover {
        background-color: $apple-green-hover;
      }
    }

    & h1 {
      color: #FFFFFF;
      font-size: 3.75rem;
      text-align: center;
      text-transform: uppercase;

      & span {
        color: $apple-green;
        text-transform: uppercase;
      }
    }
  }
}

.services {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 60px;

  & h1.title {
    text-align: center;
    font-size: 2.0rem;
    margin-bottom: 0;
    color: $apple-green;
  }

  & p.subtitle {
    text-align: center;
    margin-bottom: 26px;
  }

  & ul.items {
    display: flex;
    flex-flow: row wrap;
    margin: -10px;
    padding: 0;
    gap: 15px;

    & li {
      list-style: none;
      text-align: center;
      padding: 50px;
      width: calc(50% - 15px);
      flex: 1 auto;
      margin: 0;
      min-height: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: $apple-green;
      color: #FFFFFF;
      transition: all ease .3s;

      &:hover {
        background-color: $apple-green-hover;
      }

      & .title {
        font-size: 1.75rem;
        font-weight: bold;
        color: #FFFFFF;
      }

      & p {
        color: #FFFFFF;
      }

      & a {
        color: #FFFFFF;
      }
    }
  }
}

.select-us {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: $light-gray;

  & div.item {
    width: 40%;
    text-align: center;
    position: relative;

    & .content {
      background-color: #FFFFFF;
      margin-right: -50px;
      margin-top: -200px;
      width: 600px;
      padding: 30px;
      position: absolute;
      right: -40px;
      z-index: 200;

      & h1.title {
        font-size: 1.5rem;
        color: $apple-green;
      }
    }
  }

  & div.images {
    background-image: url("./images/family-1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 60%;
    height: 600px;
  }
}

.btn-call-to-action {
  padding: 12px 16px;
  border: none;
  background-color: $apple-green;
  color: #FFFFFF;
  font-size: 16px;
  transition: all ease .3s;
  &:hover {
    background-color: $apple-green-hover;
    color: #FFFFFF;
  }
}

.btn-call-to-action-white {
  padding: 12px 16px;
  border: none;
  background-color: #FFFFFF;
  font-size: 16px;
  color: $apple-green;
  transition: all ease .3s;
  &:hover {
    background-color: $apple-green-hover;
    color: #FFFFFF;
  }
}

.cta {
  width: 100%;
  padding: 40px;
  background-color: $apple-green;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & h3 {
    font-size: 1.50rem;
    text-align: center;
    color: #FFFFFF;
  }
}

footer {
  width: 100%;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & .footer-logo {
    & img {
      width: 240px;
      z-index: 500;
    }
  }
}

.page-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  text-align: center;
  background: url("./images/banner_headder.jpg") center no-repeat;
  background-size: cover;
  height: 520px;
  position: relative;
  flex-direction: column;

  & .opacity {
    background-color: rgba(0, 0, 0, .6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & h1 {
    color: #FFFFFF;
    font-size: 2.75rem;
    z-index: 1000;
    margin: 0;
  }

  & .submenu {
    z-index: 1000;
    display: inline-flex;
    gap: 10px;
    font-size: 1.25rem;
    padding: 0;

    & li {
      list-style: none;
      color: #FFFFFF;

      &:after {
        content: "/";
        margin-left: 15px;
      }

      &:last-child:after {
        content: "";
        margin-left: 0;
      }

      & a {
        color: #FFFFFF;
      }
    }
  }
}

.page-content {
  width: 95%;
  margin: -100px auto 0 auto;
  z-index: 2000;
  background-color: $light-gray;
  position: relative;

  & .content {
    padding: 40px;
    width: 90%;
    margin: 0 auto;

    & h1 {
      text-align: center;
      color: $apple-green;
      font-size: 1.5rem;
      margin-bottom: 0;
    }

    & .page-text-content {
      padding: 0 40px;
      text-align: left;
    }

    & .content-images {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: 20px;

      & img {
        width: 100%;
        margin: 0 auto;
        display: block;
      }
    }
  }
}

.contact {
  width: 100%;
  background-color: $light-gray;

  & .contact-term {
    font-size: 10px;
  }

  & .content {
    display: flex;
    justify-content: center;
    align-items: start;
    margin: 0 auto;
    padding: 120px 40px;
    max-width: 1280px;

    & div.contact-info {
      width: 40%;

      & div.info-item {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        background-color: $apple-green;
        margin-bottom: 10px;

        & ul.social-medias {
          display: flex;
          width: 100%;
          padding: 0;
          gap: 24px;
          justify-content: center;
          align-items: center;

          & li {
            list-style: none;
            color: #FFFFFF;

            & a {
              color: #FFFFFF;
              font-size: 1.5rem;

              & svg path {
                fill: #FFFFFF;
              }
            }
          }
        }

        & .item-icon {
          font-size: 1.5rem;
          background-color: #FFFFFF;
          padding: 12px;
          margin: 12px;

          svg {
            & path {
              fill: $apple-green;
            }
          }
        }

        & .item-info {
          margin-left: 20px;

          p, span, a {
            color: #FFFFFF;
            margin: 0;
            text-decoration: none;
          }

          p {
            font-weight: bold;
            margin-bottom: .5rem;
          }
        }
      }
    }

    & div.contact-form {
      width: 60%;
      padding: 0 60px;

      & form {
        width: 100%;

        & div.fields {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 12px;
          margin-bottom: 10px;

          & div {
            width: calc(50% - 15px);
            flex: 1 1 auto;

            & input, & textarea {
              width: 100%;
              padding: 16px;
            }

            & textarea {
              resize: none;
            }
          }
        }

        & h4 {
          margin: 0 0 10px 0;
          font-weight: bold;
          font-size: 1.25rem;
        }
      }
    }
  }
}

.button-send {
  padding: 16px 12px;
  background-color: $apple-green;
  color: #FFFFFF;
  border: none;

  &:hover {
    cursor: pointer;
    background-color: rgba($apple-green, 0.2);
    transition: all ease 400ms;
  }
}

input, textarea, input:focus, textarea:focus, input:hover, textarea:hover {
  border: 1px solid $light-gray;
  outline: none;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.benefits {
  padding: 40px;

  & h2 {
    text-align: center;
    font-size: 1.5rem;
  }

  & .items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    & .item {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      width: calc(33% - 48px);
      margin: 12px;

      & .icon {
        padding: 8px;
        background-color: $apple-green;

        & svg {
          width: 50px;
          fill: #FFFFFF;
        }
      }

      & .content {
        padding: 24px;
      }
    }
  }
}

.prices {
  background-color: $light-gray;
  width: 100%;
  padding: 40px;

  & h2 {
    text-align: center;
  }

  & .items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 24px;

    & .item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      border-top: $apple-green 5px solid;
      background-color: #FFFFFF;
      color: #FFFFFF;
      padding: 48px;
      flex: 10% auto;
      gap: 12px;

      & .icon {
        font-size: 4.5rem;
      }

      & .price {
        font-size: 2.0rem;
        color: $apple-green;
        margin: 24px auto;
        font-weight: bold;
      }

      & p {
        margin: 0;
      }

      & a {
        background-color: $apple-green;
        color: #FFFFFF;
        padding: 14px 16px;
      }
    }
  }
}

/* Mobile device */
@media screen and (max-width: 512px) {
  .app-header {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: auto;
    background-color: #FFFFFF;
    position: relative;
    top: 0;
    left: 0;
    z-index: 2500;

    & .mobile-menu {
      display: block;
      position: absolute;
      right: 25px;
      font-size: 1.25rem;
    }

    & .app-logo {
      position: relative;
      left: 0;

      & img {
        width: 120px;
      }
    }

    & .app-navigation {
      display: none;

      &.active {
        display: flex;
      }

      & .close-mobile-menu {
        position: absolute;
        top: 40px;
        right: 40px;
        display: block;
      }

      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      width: 100%;
      height: 100%;
      left: 0;
      background-color: #FFFFFF;
      z-index: 1000;

      & ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        justify-content: center;
        align-items: center;

        & li {
          list-style: none;

          & a {
            text-decoration: none;
            color: black;

            &.active {
              color: $apple-green;
            }
          }
        }
      }
    }
  }

  .app-container {
    margin-top: auto;
  }

  .app-banner {
    width: 100%;
    height: 80vh;
    display: flex;
    position: relative;
    background-image: url(./images/family_small.jpg);
    background-size: cover;
    background-position: top;

    & .opacity {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }

    & .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 90%;
      margin: -100px auto 0 auto;
      padding: 0;
      z-index: 1000;

      & a {
        color: #FFFFFF;
        padding: 12px 16px;
        background-color: $apple-green;
      }

      & h1 {
        color: #FFFFFF;
        font-size: 2.5rem;
        text-align: center;

        & span {
          color: $apple-green;
          text-transform: uppercase;
        }
      }
    }
  }

  .services {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 15px;

    & h1.title {
      text-align: center;
      font-size: 1.5rem;
      margin-bottom: 0;
      color: $apple-green;
    }

    & p.subtitle {
      text-align: center;
      margin-bottom: 26px;
    }

    & ul.items {
      display: flex;
      flex-flow: row wrap;
      margin: 0 auto;
      padding: 0;

      & li {
        list-style: none;
        text-align: center;
        padding: 20px;
        width: 50%;
        flex: 1 auto;
        margin: 5px;
        min-height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: $apple-green;
        color: #FFFFFF;

        &:hover {
          background-color: $apple-green-hover;
        }

        & .title {
          font-size: 1.75rem;
          font-weight: bold;
          color: #FFFFFF;
        }

        & p {
          color: #FFFFFF;
        }

        & a {
          color: #FFFFFF;
          font-size: .85rem;
        }
      }
    }
  }

  .select-us {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $light-gray;

    & div.item {
      width: 100%;
      text-align: center;
      position: relative;
      order: 1;

      & .content {
        background-color: #FFFFFF;
        margin: 0;
        width: 100%;
        padding: 30px;
        position: relative;
        right: unset;
        z-index: 200;

        & h1.title {
          font-size: 1.5rem;
          color: $apple-green;
        }
      }
    }

    & div.images {
      background-image: url("./images/family-1.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 300px;
      order: 0;
    }
  }

  .btn-call-to-action {
    padding: 24px 16px;
    border: none;
    background-color: $apple-green;
    color: #FFFFFF;
    font-size: 16px;
    width: 100%;
    display: block;
    text-align: center;
  }

  .cta {
    width: 100%;
    padding: 40px;
    background-color: $apple-green;
  }

  footer {
    width: 100%;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & .footer-logo {
      & img {
        width: 240px;
        z-index: 500;
      }
    }
  }


  .page-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    text-align: center;
    background: url("./images/banner_headder.jpg") center no-repeat;
    background-size: cover;
    height: 320px;
    position: relative;
    flex-direction: column;

    & .opacity {
      background-color: rgba(0, 0, 0, .6);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    & h1 {
      color: #FFFFFF;
      font-size: 1.95rem;
      z-index: 1000;
      margin: -100px 0 0 0;
    }

    & .submenu {
      z-index: 1000;
      display: inline-flex;
      gap: 10px;
      font-size: .75rem;
      padding: 0;

      & li {
        list-style: none;
        color: #FFFFFF;

        &:after {
          content: "/";
          margin-left: 15px;
        }

        &:last-child:after {
          content: "";
        }

        & a {
          color: #FFFFFF;
        }
      }
    }
  }

  .page-content {
    width: 95%;
    margin: -100px auto 0 auto;
    z-index: 2000;
    background-color: $light-gray;
    position: relative;

    & .content {
      padding: 15px 0;

      & .page-text-content {
        padding: 0 15px;
        text-align: left;
      }

      & .content-images {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        & img {
          width: 100%;
          margin: 0 auto;
          display: block;
        }
      }
    }
  }

  .contact {
    width: 100%;
    background-color: $light-gray;

    & .content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 0 auto;
      padding: 15px;
      max-width: 1280px;

      & div.contact-info {
        width: 100%;
        order: 1;
        margin-top: 30px;

        & div.info-item {
          display: flex;
          flex-direction: row;
          justify-content: start;
          align-items: center;
          background-color: $apple-green;
          margin-bottom: 10px;

          & ul.social-medias {
            display: flex;
            width: 100%;
            padding: 0;
            gap: 24px;
            justify-content: center;
            align-items: center;

            & li {
              list-style: none;
              color: #FFFFFF;

              & a {
                color: #FFFFFF;
                font-size: 1.5rem;

                & svg path {
                  fill: #FFFFFF;
                }
              }
            }
          }

          & .item-icon {
            font-size: 1.0rem;
            background-color: #FFFFFF;
            padding: 12px;
            margin: 12px;

            svg {
              & path {
                fill: $apple-green;
              }
            }
          }

          & .item-info {
            margin-left: 20px;

            p, span, a {
              color: #FFFFFF;
              margin: 0;
              text-decoration: none;
              font-size: .75rem;
            }

            p {
              font-weight: bold;
              margin-bottom: 0;
            }
          }
        }
      }

      & div.contact-form {
        width: 100%;
        padding: 0;
        order: 0;
        text-align: center;

        & form {
          width: 100%;

          & div.fields {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 12px;
            margin-bottom: 10px;

            & div {
              width: calc(50% - 15px);
              flex: 1 1 auto;

              & input, & textarea {
                width: 100%;
                padding: 16px;
              }

              & textarea {
                resize: none;
              }
            }
          }

          & h4 {
            margin: 0 0 10px 0;
            font-weight: bold;
            font-size: 1.25rem;
          }
        }
      }
    }
  }

  .button-send {
    padding: 16px 12px;
    background-color: $apple-green;
    color: #FFFFFF;
    border: none;
    width: 100%;

    &:hover {
      cursor: pointer;
      background-color: rgba($apple-green, 0.2);
      transition: all ease 400ms;
    }
  }

  input, textarea, input:focus, textarea:focus, input:hover, textarea:hover {
    border: 1px solid $light-gray;
    outline: none;
  }

  .d-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .benefits {
    padding: 15px;

    & h2 {
      text-align: center;
      font-size: 1.5rem;
    }

    & .items {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex-wrap: wrap;

      & .item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 0;
        width: 100%;
        margin: 0;

        & .icon {
          padding: 8px;
          background-color: $apple-green;

          & svg {
            width: 100px;
            fill: #FFFFFF;
          }
        }

        & .content {
          padding: 6px 15px;
          text-align: center;
        }
      }
    }
  }

  .prices {
    background-color: $light-gray;
    width: 100%;
    padding: 40px;

    & h2 {
      text-align: center;
    }

    & .items {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 24px;

      & .item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        border-top: $apple-green 5px solid;
        background-color: #FFFFFF;
        color: #FFFFFF;
        padding: 48px;
        flex: 10% auto;
        gap: 12px;

        & .icon {
          font-size: 4.5rem;
        }

        & .price {
          font-size: 2.0rem;
          color: $apple-green;
          margin: 24px auto;
          font-weight: bold;
        }

        & p {
          margin: 0;
        }

        & a {
          background-color: $apple-green;
          color: #FFFFFF;
          padding: 14px 16px;
        }
      }
    }
  }
}